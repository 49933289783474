export function fetchIslandologies() {
  return fetch( "https://wp.thatsislandology.com/wp-json/wp/v2/islandologies?per_page=100" )
    .then( ( res ) => res.json() )
    .then( ( json ) => json )
    .catch( ( err ) => console.log( err ) );
}

export function getTwitterLink( item, location ) {
  const hashtag = "Islandology";

  if( !item ) return "";

  return encodeURI( `https://twitter.com/intent/tweet?text=${item.islandology_summary}&url=${location}&hashtags=${hashtag}` );
}

export function getFacebookLink( item, location ) {
  if( !item ) return "";

  return encodeURI( `https://www.facebook.com/sharer/sharer.php?u=${location}` );
}
