import React, {PureComponent} from "react";
import {Link, graphql} from "gatsby";
import {navigate} from "gatsby";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import Layout from "../../components/layout.js";
import BackSVG from "../../svg/chevronCircle.svg";
import HamburgerSVG from "../../svg/hamburger.svg";
import DeepDiveStorySVG from "../../svg/eye.svg";
import DeepDiveVideoSVG from "../../svg/playButton.svg";
import LearnMoreSVG from "../../svg/addOutline.svg";
import LearnMore from "./LearnMore.js";
import DeepDiveVideo from "./DeepDiveVideo.js";
import DeepDiveStory from "./DeepDiveStory.js";
import Number from "../../components/Number/Number.js";
import activeItemStyles from "./ActiveItem.module.css";
import componentsStyles from "../../components/Components.module.css";
import viewerStyles from "./ActiveItem.module.css";
import ArrowSVG from "../../svg/arrow.svg";

export default class ActiveItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      item: props.data.wordpressAcfIslandologies.acf,
      learnMoreIsVisible: false,
      deepDiveIsVisible: false,
      imageIsLoaded: false,
      videoIsLoaded: false,
      items: [],
      counterIsAtBeginning: false,
      counterIsAtEnd: false,
    };

    this.toggleLearnMore = this.toggleLearnMore.bind(this);
    this.toggleDeepDive = this.toggleDeepDive.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
  }

  componentDidMount() {
    let allItems = this.props.pathContext.items;
    allItems.sort((a, b) => {
      let firstIslandologyNumber = parseInt(a.node.acf.islandology_number, 10);
      let secondIslandologyNumber = parseInt(b.node.acf.islandology_number, 10);

      return firstIslandologyNumber - secondIslandologyNumber;
    });

    let itemsCount = allItems.length;
    this.setState({items: allItems});

    if (
      allItems[0].node.acf.islandology_number ===
      this.props.data.wordpressAcfIslandologies.acf.islandology_number
    ) {
      this.setState(state => {
        return {
          counterIsAtBeginning: true,
        };
      });
    } else if (
      allItems[itemsCount - 1].node.acf.islandology_number ===
      this.props.data.wordpressAcfIslandologies.acf.islandology_number
    ) {
      this.setState(state => {
        return {
          counterIsAtEnd: true,
        };
      });
    }
  }

  toggleLearnMore() {
    this.setState(state => {
      return {learnMoreIsVisible: !state.learnMoreIsVisible};
    });
  }

  toggleDeepDive(e) {
    e.preventDefault();

    this.setState(state => {
      return {deepDiveIsVisible: !state.deepDiveIsVisible};
    });
  }

  handleNext() {
    let currentNode = this.state.item.islandology_number;
    let currentNodeIndex = this.state.items.findIndex(
      elem => elem.node.acf.islandology_number === currentNode
    );

    if (currentNodeIndex !== -1) {
      navigate(
        `/islandologies/${this.state.items[currentNodeIndex + 1].node.acf.islandology_number}`
      );
    }
  }

  handlePrev() {
    let currentNode = this.state.item.islandology_number;
    let currentNodeIndex = this.state.items.findIndex(
      elem => elem.node.acf.islandology_number === currentNode
    );

    if (currentNodeIndex !== -1) {
      navigate(
        `/islandologies/${this.state.items[currentNodeIndex - 1].node.acf.islandology_number}`
      );
    }
  }

  render() {
    const {
      item,
      learnMoreIsVisible,
      deepDiveIsVisible,
      counterIsAtBeginning,
      counterIsAtEnd,
    } = this.state;

    const hasDeepDive = !!item.has_deep_dive;

    const deepDiveIsVideo =
      item && item.has_deep_dive && item.deep_dive_type === "Video";

    const backLinkAction = deepDiveIsVisible
      ? this.toggleDeepDive
      : this.toggleLearnMore;
    const learnMoreLink =
      learnMoreIsVisible || deepDiveIsVisible ? (
        <span>
          <img src={BackSVG} alt="" /> <span>Back</span>
        </span>
      ) : (
        <span>
          <img src={LearnMoreSVG} alt="" /> <span>Learn More</span>
        </span>
      );

    const containerClasses = [activeItemStyles.container];
    if (learnMoreIsVisible)
      containerClasses.push(activeItemStyles.learnMoreIsVisible);

    const videoStyles = [componentsStyles.centeredMedia];
    videoStyles.push(componentsStyles.isLoaded);

    const metaTitle = item.seo_title
      ? item.seo_title
      : `Islandology #${item.islandology_number} | That's Islandology`;
    const metaDescription = item.seo_description
      ? item.seo_description
      : item.islandology_summary;

    return (
      <Layout view="ActiveItem">
        <Helmet
          title={metaTitle}
          meta={[
            {
              name: `description`,
              content: metaDescription,
            },
            {
              property: `og:title`,
              content: metaTitle,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image`,
              content: item.islandology_bkgd.localFile.relativePath,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:title`,
              content: metaTitle,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
          ].concat()}
        />
        <div className={containerClasses.join(" ")}>
          <div className={activeItemStyles.visual}>
            {item.islandology_bkgd && (
              <Img
                alt={item.islandology_summary}
                className={activeItemStyles.image}
                sizes={
                  item.islandology_bkgd &&
                  item.islandology_bkgd.localFile.childImageSharp.sizes
                }
              />
            )}
            {item.ambient_video && (
              <video
                className={videoStyles.join(" ")}
                autoPlay="autoplay"
                loop="loop"
                muted
                inline="true"
                playsInline
                src={item.ambient_video.url}
              />
            )}

            <div className={componentsStyles.gradient} />

            <Link className={activeItemStyles.goBack} to="/">
              <img src={HamburgerSVG} alt="" />
            </Link>

            <div className={activeItemStyles.context}>
              <Number value={item.islandology_number} view="activeItem" />
              <h1
                className={activeItemStyles.title}
                dangerouslySetInnerHTML={{__html: item.islandology_summary}}
              />
              <div
                className={activeItemStyles.learnMore}
                onClick={backLinkAction}
              >
                {learnMoreLink}
              </div>
            </div>

            {!learnMoreIsVisible && !counterIsAtEnd && (
              <div className={viewerStyles.next} onClick={this.handleNext}>
                <img src={ArrowSVG} alt="" />
              </div>
            )}

            {!learnMoreIsVisible && !counterIsAtBeginning && (
              <div className={viewerStyles.prev} onClick={this.handlePrev}>
                <img src={ArrowSVG} alt="" />
              </div>
            )}
          </div>

          <LearnMore
            item={item}
            isHidden={!learnMoreIsVisible}
            onClose={this.toggleLearnMore}
          />

          {hasDeepDive && !learnMoreIsVisible && !deepDiveIsVisible && (
            <span className={activeItemStyles.diveButton}>
              <a
                className={componentsStyles.diveButton}
                onClick={this.toggleDeepDive}
                href="#0"
              >
                {deepDiveIsVideo ? (
                  <img src={DeepDiveVideoSVG} alt="" />
                ) : (
                  <img src={DeepDiveStorySVG} alt="" />
                )}
                <span>{item.islandology_primary_cta}</span>
              </a>
            </span>
          )}

          {hasDeepDive && deepDiveIsVisible && deepDiveIsVideo && (
            <DeepDiveVideo item={item} onClose={this.toggleDeepDive} />
          )}

          {hasDeepDive && !deepDiveIsVideo && (
            <DeepDiveStory
              item={item}
              isHidden={!deepDiveIsVisible && !deepDiveIsVideo}
              onClose={this.toggleDeepDive}
            />
          )}
        </div>
      </Layout>
    );
  }
}

export const islandologyQuery = graphql`
  query($id: String!) {
    wordpressAcfIslandologies(id: {eq: $id}) {
      acf {
        ambient_video
        featured_islandology
        islandology_number
        islandology_overview
        islandology_primary_cta
        islandology_summary
        islandology_video
        related_content_headline
        related_content {
          label
          link
          image {
            localFile {
              relativePath
              childImageSharp {
                id
                sizes(maxWidth: 400) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
        islandology_bkgd {
          localFile {
            relativePath
            childImageSharp {
              sizes(maxWidth: 1400) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
        featured_islandology_image {
          localFile {
            childImageSharp {
              sizes(maxWidth: 1400) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
        has_deep_dive
        deep_dive_type
        deep_dive_video
        deep_dive_story
        seo_title
        seo_description
      }
    }
  }
`;
