import React from "react";
import YouTube from 'react-youtube';

import CloseSVG from "../../svg/closeCircle.svg";
import deepDiveVideoStyles from "./DeepDiveVideo.module.css";

function onReady() {
  console.log( "ready" );
}

const DeepDiveVideo = ( { item, onClose } ) => {
  const video = item.deep_dive_type === "Video"
        && item.deep_dive_video;

  const segments = video.split( "/" );
  const id = segments[segments.length - 1];

  const opts = {
    playerVars: { // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      frameborder: 0,
      playsinline: 1,
      rel: 0,
      showinfo: 0,
    }
  };

  return (
    <div
      className={deepDiveVideoStyles.container}
    >
      <div className={deepDiveVideoStyles.video}>
        <YouTube
          videoId={id}
          opts={opts}
          onReady={onReady}
        />
      </div>

      <a
        className={deepDiveVideoStyles.close}
        onClick={onClose}
        href="#0"
      >
        <img src={CloseSVG} alt="" />
      </a>
    </div>
  );
};

export default DeepDiveVideo;
