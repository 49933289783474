import React from "react";

import CloseSVG from "../../svg/closeCircleDark.svg";
import deepDiveStoryStyles from "./DeepDiveStory.module.css";

const DeepDiveStory = ( { item, isHidden, onClose } ) => {
  const content = item.deep_dive_type === "Story" && item.deep_dive_story;
  const styles = [ deepDiveStoryStyles.container ];
  if ( isHidden ) styles.push( deepDiveStoryStyles.isHidden );

  return (
    <div
      className={styles.join( " " )}
    >
      <div className={deepDiveStoryStyles.content}>
        <div
          dangerouslySetInnerHTML={{__html: content}}
        />
      </div>

      <a
        className={deepDiveStoryStyles.close}
        onClick={onClose}
        href="#0"
      >
        <img src={CloseSVG} alt="" />
      </a>
    </div>
  );
};

export default DeepDiveStory;
