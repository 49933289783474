import React from "react";
import FacebookSVG from "../../svg/facebook.svg";
import TwitterSVG from "../../svg/twitter.svg";
import CloseSVG from "../../svg/closeCircle.svg";

import { getTwitterLink, getFacebookLink } from "../../helpers.js";
import learnMoreStyles from "./LearnMore.module.css";

const LearnMore = ( { item, isHidden, onClose } ) => {
  const styles = [ learnMoreStyles.container ];
  if ( isHidden ) styles.push( learnMoreStyles.isHidden );

  const location = typeof window !== "undefined" && window.location.href;

  return (
    <div
      className={styles.join( " " )}
    >
      <div className={learnMoreStyles.content}>
        <div
          className={learnMoreStyles.overview}
          dangerouslySetInnerHTML={{__html: item.islandology_overview}}
        />

        <p className={learnMoreStyles.relatedContentHeadline}>
          {item.related_content_headline}
        </p>

        <div className={learnMoreStyles.relatedContent}>
          { item.related_content.map( ( content, i ) => {
            const linkStyle = content.image && {backgroundImage: `url(${content.image.localFile.childImageSharp.sizes.src})`};

            return (
              <span key={i}>
                <span className={learnMoreStyles.label}>
                  {content.label}
                </span>
                <a
                  className={learnMoreStyles.relatedContentBlock}
                  href={content.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={linkStyle}
                >
                  <span>{content.label}</span>
                </a>
              </span>
            );
          } ) }
        </div>

        <div className={learnMoreStyles.share}>
          <a
            href={getFacebookLink( item, location )}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={FacebookSVG} alt="" />
          </a>
          <a
            href={getTwitterLink( item, location )}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={TwitterSVG} alt="" />
          </a>
        </div>
      </div>

      <a
        className={learnMoreStyles.close}
        onClick={onClose}
        href="#0"
      >
        <img src={CloseSVG} alt="" />
      </a>
    </div>
  );
};

export default LearnMore;
